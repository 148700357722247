import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

import favicon from "../images/favicon.png";
import metaImage from "../images/og_image.png";

interface SeoProps {
  title?: string;
  description?: string;
  slug: string;
}

const Seo: FC<SeoProps> = ({ title, description, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
        }
      }
    }
  `);

  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: data.title,
    image: `${data.site.siteMetadata.siteUrl}${metaImage}`,
    "@id": data.site.siteMetadata.siteUrl,
    url: data.site.siteMetadata.siteUrl,
    telephone: "(419) 852-6314",
    address: {
      "@type": "PostalAddress",
      streetAddress: "419 S. Main",
      addressLocality: "Celina",
      addressRegion: "OH",
      postalCode: "45822",
      addressCountry: "US",
    },
    // openingHoursSpecification: {
    //   "@type": "OpeningHoursSpecification",
    //   dayOfWeek: ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    //   opens: "13:00",
    //   closes: "17:00",
    //   sameAs: [
    //     "https://www.facebook.com/profile.php?id=100088511315201",
    //   ],
    // },
  };

  return (
    <Helmet htmlAttributes={{ lang: `en` }}>
      <title>
        {title ? `${title} | Papa Monkey Bikes` : "Papa Monkey Bikes"}
      </title>
      <link rel="icon" type="image/png" href={favicon} />
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />
      <meta
        name="keywords"
        content="papa monkey bikes, bike, bicycle, bike restoration, vintage bikes, custom bikes"
      />
      <link
        rel="canonical"
        href={`${data.site.siteMetadata.siteUrl}/${slug}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description || data.site.siteMetadata.description}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${data.site.siteMetadata.siteUrl}/${slug}`}
      />
      <meta property="og:site_name" content={data.site.siteMetadata.title} />

      <meta
        property="og:image"
        content={`${data.site.siteMetadata.siteUrl}${metaImage}`}
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta name="author" content="Papa Monkey Bikes" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default Seo;
